@import '../../variables';

.answer {
  position: relative;

  width: 100%;
  height: 100%;

  &--white-cover {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    background: #fff;

    animation: 0.5s $ease-standard forwards survey-unveil-animation;
    pointer-events: none;
  }

  &--header {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    padding: 2vh 1vh;

    animation: 1s $ease-standard 1s backwards survey-header-animation;

    .icon-button {
      --size: 5vh;

      color: #fff;
      font-size: 3.5vh;

      &:hover {
        background: transparent;
      }
    }

    &__spacer {
      width: 5vh;
    }
  }

  &--grid-contianer {
    display: grid;
    grid-template-rows: 30% 70%;
    height: 100%;
  }

  &--question {
    display: flex;
    flex-direction: column;
    align-items: center;

    height: 100%;
    padding: 2vh;

    &__spacer1 {
      flex: 3;
    }
    &__spacer2 {
      flex: 2;
    }
    &__text {
      flex: 0 0 auto;

      color: #fff;
      font-size: min(3.5vh, 6vw);
      line-height: 1.375em;
      font-weight: 500;
      text-align: center;

      animation: 1s $ease-standard backwards survey-text-animation;
    }
  }

  &--options {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 100%;

    border-radius: 6vh 6vh 0 0;
    background: #fff;

    animation: 1s $ease-standard 1s backwards survey-footer-animation;
  }
}

@keyframes survey-text-animation {
  0% {
    transform: translate(0, 1em);
    opacity: 0;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}

@keyframes survey-circle-animation {
  0% {
    transform: scale(0) rotate(-15deg);
    opacity: 0;
  }
  100% {
    transform: scale(1) rotate(0);
    opacity: 1;
  }
}

@keyframes survey-header-animation {
  0% {
    transform: translate(0, -100%);
    opacity: 0;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}

@keyframes survey-footer-animation {
  0% {
    transform: translate(0, 100%);
    opacity: 0;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}

@keyframes survey-unveil-animation {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes survey-back-btn-animation {
  0% {
    transform: translate(-100%, 0);
    opacity: 0;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}
