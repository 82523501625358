@import '../../variables';

$default-delay: 1.5s;

.long-options {
  width: 100%;
  max-width: 100vh;
  max-height: 100%;
  padding: 3vh;
  overflow-y: auto;

  text-align: center;

  .long-option-item {
    animation: 1s $ease-standard $default-delay backwards survey-text-animation;

    @for $i from 2 to 10 {
      &:nth-child(#{$i}) {
        animation-delay: #{$default-delay + 0.3 * ($i - 1)};
      }
    }

    &:not(:first-child) {
      margin-top: 3vh;
    }
  }

  &--option-circle,
  &--white-circle {
    position: fixed;
    z-index: 101;
    transform: scale(0);

    width: 100px;
    height: 100px;
  }
}
