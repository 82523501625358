@use 'sass:math';
@import '../../variables';

.number-question {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  padding: 6vh;

  &--calc {
    $grid-size: 7.5vh;

    display: grid;
    grid-template-columns: repeat(4, $grid-size);
    grid-template-rows: repeat(5, $grid-size);
    grid-gap: 1.5vh;
    grid-template-areas:
      'd  d  d   d'
      'b7 b8 b9  bback'
      'b4 b5 b6  bclear'
      'b1 b2 b3  .'
      'b0 b0 b00 .';

    animation: 1s $ease-standard 1.5s backwards survey-text-animation;

    &__display {
      grid-area: d;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      width: 100%;
      height: 100%;
      padding-right: math.div($grid-size, 3);

      border: 4px solid $purple500;
      border-radius: math.div($grid-size, 2);
      background: #fff;

      overflow: hidden;

      color: $gray700;
      font-size: $grid-size * 0.4;
      font-weight: 500;
      white-space: pre;
    }

    @mixin btn($value) {
      grid-area: #{$value};
      display: flex;
      align-items: center;
      justify-content: center;

      width: 100%;
      height: 100%;

      border-radius: math.div($grid-size, 2);
      background: $purple100;

      cursor: pointer;
      user-select: none;

      color: $gray700;
      font-size: $grid-size * 0.4;
      font-weight: 500;
    }
    @mixin btn-filled($value) {
      @include btn($value);

      background: $purple500;
      color: #fff;
    }

    @for $value from 0 to 10 {
      &__btn-#{$value} {
        @include btn(b#{$value});
      }
    }
    &__btn-00 {
      @include btn(b00);
    }
    &__btn-back {
      @include btn-filled(bback);
    }
    &__btn-clear {
      @include btn-filled(bclear);
    }
  }

  &--button-wrapper {
    position: absolute;
    bottom: 3vh;
    right: 3vh;

    animation: 1s $ease-standard 2.5s backwards survey-footer-animation;

    .raised-button {
      padding: 1.5vh 3vh;
      border-radius: 0.8vh;

      font-size: 2.3vh;
    }
  }

  &--purple-circle,
  &--white-circle {
    position: fixed;
    z-index: 101;
    transform: scale(0);

    width: 100px;
    height: 100px;
  }
}
