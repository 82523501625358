@import url('https://fonts.googleapis.com/css?family=Noto+Sans+JP:400,500,700&display=swap');

* {
  font-family: 'Noto Sans JP', sans-serif;
}

button:focus:not(.focus-visible),
input[type='button']:focus:not(.focus-visible) {
  outline: none;
}

a,
a:visited,
a:hover {
  text-decoration: none;
}

a {
  color: #3b82f6;
}

p {
  color: #4b5563;
}
