@import '../../variables';

.start {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
  padding: 3vh;

  text-align: center;

  &--title {
    color: #fff;
    font-size: min(5.5vh, 9vw);
    font-weight: 500;

    animation: 1s $ease-standard backwards survey-text-animation;
  }
  &--detail {
    margin-top: 3vh;

    color: rgba(255, 255, 255, 0.8);
    font-size: min(2.3vh, 4vw);

    animation: 1s $ease-standard 0.5s backwards survey-text-animation;
  }
  &--button {
    width: 30vh;
    height: 30vh;
    margin-top: 9.3vh;

    color: $purple500;
    font-size: 3vh;
    font-weight: bold;

    animation: 1s $ease-standard 0.5s backwards survey-circle-animation;
  }

  &--footer {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;

    width: 100%;

    animation: 1s $ease-standard 1.5s backwards survey-footer-animation;

    &__container {
      display: flex;
      align-items: center;
      justify-content: space-between;

      min-width: 70vw;
      height: 4rem;
      padding: 0 4rem;

      border-radius: 4rem 4rem 0 0;
      background: rgba(255, 255, 255, 0.95);
    }
    &__logo {
      height: 3vh;
      margin-top: 4px;
    }
    &__copyright {
      margin-left: 4rem;

      font-weight: 500;
      color: $purple500;
    }

    @media (max-width: 450px) {
      &__container {
        width: 90vw;
        height: 14.2vw;
        padding: 0 14.2vw;

        border-radius: 14.2vw 14.2vw 0 0;
      }
      &__logo {
        width: 15.5vw;
        height: auto;
        margin-top: 0.8vw;
      }
      &__copyright {
        margin-left: 0;

        font-size: 3.5vw;
      }
    }
  }
}
