@import '../../variables';

.circle-button {
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 50%;
  background: var(--color);
  box-shadow: 0 2px 6px 0 var(--shadow-color-1),
    0 2px 4px 0 var(--shadow-color-2);

  transition: 0.3s box-shadow $ease-standard;

  font-weight: bold;

  &:hover {
    box-shadow: 0 8px 12px -1px var(--shadow-color-1),
      0 4px 8px -1px var(--shadow-color-2);
  }
  &:focus {
    outline: none;
  }

  &.animating {
    z-index: 100;

    transition: box-shadow 0.3s $ease-standard,
      transform 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
  }
}
