@import '../../variables';

.line-question {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  padding: 6vh;

  &--input {
    width: 100%;
    padding: 1.5vh 0.75em;
    border: 0.4vh solid $gray600;
    border-radius: 1.5vh;

    color: $gray700;
    font-size: 2.5vh;
    line-height: 1.5em;
    font-weight: 500;

    animation: 1s $ease-standard 1.5s backwards survey-text-animation;

    &:focus {
      outline: none;
    }
  }

  &--button-wrapper {
    position: fixed;
    bottom: 3vh;
    right: 3vh;

    animation: 1s $ease-standard 2.5s backwards survey-footer-animation;

    .raised-button {
      padding: 1.5vh 3vh;
      border-radius: 0.8vh;

      font-size: 2.3vh;
    }
  }

  &--purple-circle,
  &--white-circle {
    position: fixed;
    z-index: 101;
    transform: scale(0);

    width: 100px;
    height: 100px;
  }
}
