@import '../../variables';

.completed {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  height: 100%;
  background: #fff;

  &--spacer {
    flex: 1 0 0;
  }
  &--spacer-large {
    flex: 3 0 0;
  }

  &--message {
    color: $purple500;
    font-size: 4.5vh;
    font-weight: bold;
    text-align: center;

    animation: 1s $ease-standard 1s backwards survey-text-animation;
  }

  &--icon {
    color: $purple500;
    font-size: 25vh;

    animation: 1s $ease-standard backwards survey-circle-animation;
  }

  &--btns {
    position: absolute;
    bottom: 3vh;
    left: 3vh;

    animation: 1s $ease-standard 1.5s backwards survey-back-btn-animation;

    .completed--back-btn {
      color: $purple500;
      font-size: 2.2vh;
      font-weight: bold;

      &:not(:disabled):hover {
        opacity: 0.8;
        color: $purple500;
      }

      svg {
        font-size: 3vh;
      }
    }
  }
}
