.loading-spinner {
  display: inline-block;
  width: 1em;
  height: 1em;
  animation: loading-spinner_rotate 2s linear 0s infinite forwards;
  vertical-align: -0.125em;

  &--path {
    animation: loading-spinner_dash 1.5s ease-in-out 0s infinite forwards;
  }
}

@keyframes loading-spinner_rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes loading-spinner_dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 45, 200;
    stroke-dashoffset: -17px;
  }
  100% {
    stroke-dasharray: 45, 200;
    stroke-dashoffset: -62px;
  }
}
