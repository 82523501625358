@import '../../variables';

$default-delay: 1.5s;

.balloon-options {
  display: grid;
  grid-gap: 3vh;
  align-items: center;
  justify-content: center;

  width: 100%;
  padding: 0 3vh;

  &--option {
    --size: 19vh;

    width: var(--size);
    height: var(--size);
    padding: 1.25vh;

    color: #fff;
    font-size: min(3.5vh, 6vw);
    font-weight: 500;

    animation: 1s $ease-standard $default-delay backwards
      survey-circle-animation;

    &.long-text {
      font-size: 2.5vh;
    }

    @for $i from 2 to 10 {
      &:nth-child(#{$i}) {
        animation-delay: #{$default-delay + 0.3 * ($i - 1)};
      }
    }
  }

  &--white-circle {
    position: fixed;
    z-index: 101;
    transform: scale(0);

    width: 100px;
    height: 100px;
  }
}
