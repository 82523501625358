@import '../../variables';

.demo-badge {
  position: fixed;
  bottom: 3vh;
  left: 3vh;
  z-index: 1000;

  padding: 0.5em 1em;
  border: 2px solid $purple500;
  border-radius: 0.5em;
  background: #fff;

  color: $purple500;
  font-size: 2vh;
  font-weight: bold;

  user-select: none;
}
