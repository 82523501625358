$gray100: #f7fafc;
$gray200: #edf2f7;
$gray300: #e2e8f0;
$gray400: #cbd5e0;
$gray500: #adaec0;
$gray600: #718096;
$gray700: #4a5568;
$gray800: #2d3748;
$gray900: #1a202c;

$blue100: #ebf8ff;
$blue500: #4299e1;

$green100: #f0fff4;
$green500: #48bb78;

$red500: #f56565;

$orange500: #ed8936;
$orange600: #dd6b20;

$yellow500: #ecc94b;
$yellow600: #d69e2e;
$yellow700: #b7791f;
$yellow800: #975a16;

$purple500: #b000ff;
$purple100: #e5ccff;

$purple-gradient-1: #7f00ff;
$purple-gradient-2: #e100ff;

$sm: 640px;
$md: 768px;
$lg: 1024px;
$xl: 1280px;

$ease-standard: cubic-bezier(0.4, 0, 0.2, 1); // Material Designの標準イージング
$ease-decelerate: cubic-bezier(0, 0, 0.2, 1); // Material Designの減速イージング
$ease-accelerate: cubic-bezier(0.4, 0, 1, 1); // Material Designの加速イージング
$ease-out-quint: $ease-standard; // いままで下のイージングだったが、まとめて変更
// $ease-out-quint: cubic-bezier(0.23, 1, 0.32, 1);

$gnav-expanded-width: 16rem;
$gnav-narrow-width: 4rem;
$gnav-expand-threshold: $lg;

body {
  --gray100: #f7fafc;
  --gray200: #edf2f7;
  --gray300: #e2e8f0;
  --gray400: #cbd5e0;
  --gray500: #adaec0;
  --gray600: #718096;
  --gray700: #4a5568;
  --gray800: #2d3748;
  --gray900: #1a202c;

  --blue100: #ebf8ff;
  --blue500: #4299e1;

  --green100: #f0fff4;
  --green500: #48bb78;

  --red500: #f56565;

  --orange500: #ed8936;
  --orange600: #dd6b20;

  --yellow500: #ecc94b;
  --yellow600: #d69e2e;
  --yellow700: #b7791f;
  --yellow800: #975a16;

  --purple500: #b000ff;
}
