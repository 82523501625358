@import '../../../variables';

.icon-button {
  --size: 2.5rem;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  width: var(--size);
  height: var(--size);

  border-radius: 50%;

  transition: 0.2s;

  font-size: 1.25rem;
  color: $gray700;

  &:hover {
    background: $gray100;
  }
}
